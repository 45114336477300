import React from "react";
import { BaseTitle } from "../parts/BaseTitle";
import { ViewMoreButton } from "../parts/ViewMoreButton";
import { CLIENT_CONNECTING_STATE } from "../../constants/api";
import { Loading } from "../svg/Loading";

type TopPageRecruitSection = {
  isActive: boolean;
  connectingState: CLIENT_CONNECTING_STATE;
};
export const TopRecruitSection: React.FC<TopPageRecruitSection> = (props) => {
  const { isActive, connectingState } = props;

  return (
    <section className="flex flex-col justify-between md:w-1/2 px-5 md:px-0 mb-[50px]  md:mb-0">
      <div className="border-b md:border-b-0 border-b-black pb-[50px] md:pb-0 md:border-r">
        <BaseTitle en="RECRUIT" ja="メンバー募集" />
        {connectingState !== CLIENT_CONNECTING_STATE.SUCCESS ? (
          <Loading />
        ) : (
          <>
            <p className="text-center mb-[50px] md:mb-[40px]">
              {isActive ? (
                <>
                  経験よりも気持ちが優先です！
                  <br />
                  詳しくは募集ページをご覧ください。
                </>
              ) : (
                <>現在、スタッフの募集は行っておりません。</>
              )}
            </p>
            {isActive && <ViewMoreButton href="/recruit" isAnimation={false} />}
          </>
        )}
      </div>
    </section>
  );
};
