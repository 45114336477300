import React from "react";

type DotCharProps = {
  children: React.ReactNode;
  dotSize?: string;
  dotColor?: string;
};

export const DotChar: React.FC<DotCharProps> = (props) => {
  const {
    children,
    dotSize = "before:w-[4px] md:before:w-[8px] before:h-[4px] md:before:h-[8px]",
    dotColor = "before:bg-secondary",
  } = props;

  return (
    <span
      className={`relative before:content-[''] before:block ${dotSize}
                ${dotColor} before:rounded-full before:absolute
                before:-top-[8px] before:inset-x-0 before:mx-auto`}
    >
      {children}
    </span>
  );
};
