import React from "react";
import { StaticImage } from "gatsby-plugin-image";

type TopAccessSectionProps = {
  shopInfo: {
    readonly address: string | null;
    readonly close: string | null;
    readonly open: string | null;
    readonly tel: string | null;
  } | null;
};
export const TopAccessSection: React.FC<TopAccessSectionProps> = (props) => {
  const { shopInfo } = props;

  return (
    <section className="mb-[50px] md:mb-[60px]">
      <div
        className="w-[120px] md:w-[190px] mx-auto mb-[60px] md:mb-[90px]"
        data-page-animation={true}
        data-type-fade={true}
      >
        <StaticImage
          src="../../images/common/logo_main.png"
          alt="LOG COFFEE ROASTERS LOGO"
        />
      </div>
      <div className="block md:flex md:flex-row-reverse md:justify-end md:max-w-[1000px] md:px-5 md:mx-auto mb-[60px]">
        <div
          className="w-[100%] md:w-1/2 px-5 md:px-0 mb-[40px] md:mb-0"
          data-page-animation={true}
          data-type-fade={true}
        >
          <StaticImage src="../../images/top/access.png" alt="" />
        </div>
        <dl
          className="px-5 md:mx-0 md:w-1/2 md:pr-5"
          data-page-animation={true}
          data-type-fade={true}
        >
          <div className="mb-6">
            <dt
              className="text-md md:text-lg font-bold relative pl-3 tracking-[0.12em] before:content-['']
              before:block before:w-1 before:h-[14px] before:bg-blue
              before:absolute before:left-0 before:inset-y-0 before:my-auto"
            >
              Open
            </dt>
            <dd className="text-sm md:text-md font-normal">
              {shopInfo && shopInfo.open
                ? shopInfo.open.split("\n").map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })
                : ""}
            </dd>
          </div>
          <div className="mb-6">
            <dt
              className="text-md md:text-lg font-bold relative pl-3 tracking-[0.12em] before:content-['']
              before:block before:w-1 before:h-[14px] before:bg-green
              before:absolute before:left-0 before:inset-y-0 before:my-auto"
            >
              Close
            </dt>
            <dd className="text-sm md:text-md font-normal">
              {shopInfo && shopInfo.close
                ? shopInfo.close.split("\n").map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })
                : ""}
            </dd>
          </div>
          <div className="mb-6">
            <dt
              className="text-md md:text-lg font-bold relative pl-3 tracking-[0.12em] before:content-['']
              before:block before:w-1 before:h-[14px] before:bg-primary
              before:absolute before:left-0 before:inset-y-0 before:my-auto"
            >
              Address
            </dt>
            <dd className="text-sm md:text-md font-normal">
              {shopInfo && shopInfo.address
                ? shopInfo.address.split("\n").map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })
                : ""}
            </dd>
          </div>
          <div>
            <dt
              className="text-md md:text-lg font-bold relative pl-3 tracking-[0.12em] before:content-['']
              before:block before:w-1 before:h-[14px] before:bg-yellow
              before:absolute before:left-0 before:inset-y-0 before:my-auto"
            >
              Tel / E-mail
            </dt>
            <dd className="text-sm md:text-md font-normal">
              {shopInfo && shopInfo.tel
                ? shopInfo.tel.split("\n").map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })
                : ""}
            </dd>
          </div>
        </dl>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3300.754840934312!2d131.4762848156515!3d34.17818518057181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354497032679e40d%3A0x6e0df5c86ce731c4!2z44CSNzUzLTAwODYg5bGx5Y-j55yM5bGx5Y-j5biC5Lit5biC55S677yU4oiS77yR77yU!5e0!3m2!1sja!2sjp!4v1658760133924!5m2!1sja!2sjp"
        width="100%"
        height="480"
        style={{ border: 0 }}
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="!h-[280px] md:!h-[480px]"
      ></iframe>
    </section>
  );
};
