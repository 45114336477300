import React from "react";
import { BaseTitle } from "../parts/BaseTitle";
import { ViewMoreButton } from "../parts/ViewMoreButton";

export const TopContactSection: React.FC = () => {
  return (
    <section className="flex flex-col justify-between md:w-1/2 px-5 md:px-0">
      <BaseTitle en="CONTACT" ja="お問い合わせ" />
      <p className="text-center mb-[50px] md:mb-[40px]">
        カフェ営業やコーヒー豆・関連商品の販売に関する事、
        <br />
        業務用コーヒー豆卸売販売、
        <br className="md:hidden" />
        トレーニングなどお気軽にお問い合わせ下さい。
      </p>
      <ViewMoreButton href="/contact" isPrimary={true} text="お問い合わせ" />
    </section>
  );
};
