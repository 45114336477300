import * as React from "react";
import { useEffect, useRef, useState } from "react";

export const IntroMovie: React.FC = () => {
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [showIntroMovie, setShowIntroMovie] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoLoaded = () => {
    // console.log("aaaaaaaaa");
  };

  useEffect(() => {
    setShowIntroMovie(false);
    if (window) {
      const isIntroComplete =
        sessionStorage.getItem("isIntroComplete") !== "true";
      setShowIntroMovie(isIntroComplete);
    }

    const videoElem = videoRef.current;
    if (videoElem) {
      setTimeout(() => {
        videoElem.play().then(() => {
          // console.log("played");
          setTimeout(() => {
            setIsVideoEnded(true);
          }, 3000);
          setTimeout(() => {
            sessionStorage.setItem("isIntroComplete", "true");
          }, 3800);
        });
      }, 500);
    }
  }, [videoRef]);

  return (
    <>
      {showIntroMovie && (
        <div
          className={`fixed bg-white w-[100%] h-[100%] top-0 left-0 z-[1000] overflow-hidden
        transition-opacity duration-1000 
        ${
          isVideoEnded
            ? "opacity-0 pointer-events-none"
            : "opacity-1 pointer-events-auto"
        }`}
        >
          <video
            src={
              (process.env.NODE_ENV === "development" ? "/" : "/") +
              "movies/intro.mp4"
            }
            muted={true}
            controls={false}
            playsInline={true}
            ref={videoRef}
            className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2
          max-w-none w-[120%] md:w-[700px]"
            onLoadedData={handleVideoLoaded}
          ></video>
        </div>
      )}
    </>
  );
};
