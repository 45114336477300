import React from "react";
import { CLIENT_CONNECTING_STATE, NewsPostType } from "../../constants/api";

type PickupNewsProps = {
  news: NewsPostType | null;
};

export const PickupNews: React.FC<PickupNewsProps> = (props) => {
  const { news } = props;

  return (
    <div className="pt-[40px] max-w-[100%] md:max-w-[354px]">
      {Array.isArray(news) && news.length > 0 ? (
        <a href={news[0].acf.url} target="_blank">
          <div className="text-xs pb-[5px] leading-none border-b border-gray">
            {news[0].date
              ? news[0].date.split("T")[0].replaceAll("-", ".")
              : ""}
          </div>
          <p className="pt-[4px]">{news[0].title.rendered}</p>
        </a>
      ) : (
        <div className="text-xs pb-[5px] leading-none">
          <span></span>
        </div>
      )}
    </div>
  );
};
