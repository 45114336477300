import React, { useEffect, useState } from "react";
import { Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import axios from "axios";
import { WP_INSTAGRAM_URL } from "../../constants/urls";
import { CLIENT_CONNECTING_STATE } from "../../constants/api";

export type InstagramPosts = {
  media_url: string;
  permalink: string;
}[];

export const InstagramSlider: React.FC = () => {
  const [instagramPosts, setInstagramPosts] = useState<InstagramPosts>([]);
  const [connectingState, setConnectingState] = useState(
    CLIENT_CONNECTING_STATE.IDLE
  );

  useEffect(() => {
    setConnectingState(CLIENT_CONNECTING_STATE.CONNECTING);
    axios
      .get(WP_INSTAGRAM_URL)
      .then((res) => {
        setConnectingState(CLIENT_CONNECTING_STATE.SUCCESS);
        setInstagramPosts(res.data.business_discovery.media.data);
      })
      .catch((error) => {
        setConnectingState(CLIENT_CONNECTING_STATE.FAILED);
      });
  }, []);

  return (
    <div
      className="mb-[30px] md:mb-[72px]"
      data-page-animation={true}
      data-type-fade={true}
    >
      {connectingState === CLIENT_CONNECTING_STATE.CONNECTING && (
        <p className="text-center font-bold">LOADING...</p>
      )}
      {connectingState === CLIENT_CONNECTING_STATE.SUCCESS && (
        <Swiper
          loop={true}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
            reverseDirection: false,
          }}
          speed={13000}
          slidesPerView={2.5}
          freeMode={true}
          centeredSlides={true}
          modules={[Autoplay, FreeMode]}
          breakpoints={{
            600: {
              slidesPerView: 3.8,
            },
            768: {
              slidesPerView: 4,
            },
            1000: {
              slidesPerView: 5,
            },
            1200: {
              slidesPerView: 6.1,
            },
          }}
        >
          {instagramPosts.map(
            (instagramPost) =>
              instagramPost.media_url.indexOf("video") < 0 && (
                <SwiperSlide
                  key={instagramPost.permalink}
                  className="px-[10px] md:px-[20px] xl:px-[30px]"
                >
                  <a
                    href={instagramPost.permalink}
                    target="_blank"
                    className="block rounded-sm overflow-hidden
                  overflow-hidden bg-no-repeat bg-cover bg-center pt-[100%]"
                    style={{
                      backgroundImage: `url(${instagramPost.media_url})`,
                    }}
                  ></a>
                </SwiperSlide>
              )
          )}
        </Swiper>
      )}
      {connectingState === CLIENT_CONNECTING_STATE.FAILED && (
        <p className="text-center font-bold text-sm antialiased">
          <span className="text-primary">データの取得に失敗しました。</span>
          <br />
          時間をおいてページを読み込み直してみてください。
        </p>
      )}
    </div>
  );
};
