const pageSettingsData = {
  top_page_settings: {
    shop_info: {
      open: "12:00 - 19:00（Tue）\r\n11:00 - 19:00（Wed - Sun）",
      close: "Monday & Irregular",
      address: "〒753-0086 山口県山口市中市町４−１４",
      tel: "083-902-1371 / log.coffee.roasters@gmail.com",
    },
    is_recruit_active: false,
  },
  recruit_page_settings: {
    body1: {
      title: "雇用条件",
      contents: "アルバイト",
    },
    body2: {
      title: "給与",
      contents: "時給●●円〜",
    },
    body3: {
      title: "勤務地",
      contents: "山口県山口市中市町４−１４（山口駅から徒歩**分）",
    },
    body4: {
      title: "勤務時間",
      contents: "12:00〜19:00（4時間〜のシフト制）\r\n※土日勤務できる方優先",
    },
    body5: {
      title: "業務内容",
      contents: "・お客様へのドリンクの提供\r\n・レジ業務\r\n・調理補助",
    },
    body6: {
      title: "応募資格",
      contents:
        "・コーヒーに本気で、意欲的に取り組める方\r\n・明るく、元気にコミュニケーションをとれる方\r\n・長期的に働ける方",
    },
    body7: {
      title: "",
      contents: "",
    },
    body8: {
      title: "",
      contents: "",
    },
    body9: {
      title: "",
      contents: "",
    },
    body10: {
      title: "",
      contents: "",
    },
  },
};

const newsPostData = [
  {
    id: 65,
    date: "2022-08-12T16:03:23",
    date_gmt: "2022-08-12T07:03:23",
    guid: {
      rendered: "https://log-coffee-roasters.com/wp/?p=65",
    },
    modified: "2022-09-17T22:55:56",
    modified_gmt: "2022-09-17T13:55:56",
    slug: "%e6%8a%95%e7%a8%bf%e3%83%86%e3%82%b9%e3%83%88",
    status: "publish",
    type: "post",
    link: "https://log-coffee-roasters.com/wp/2022/08/12/%e6%8a%95%e7%a8%bf%e3%83%86%e3%82%b9%e3%83%88/",
    title: {
      rendered: "投稿テスト2",
    },
    content: {
      rendered: "",
      protected: false,
    },
    excerpt: {
      rendered: "",
      protected: false,
    },
    author: 1,
    featured_media: 0,
    comment_status: "open",
    ping_status: "open",
    sticky: false,
    template: "",
    format: "standard",
    meta: [],
    categories: [1],
    tags: [],
    acf: {
      url: "https://google.com",
    },
  },
];

export enum CLIENT_CONNECTING_STATE {
  "IDLE",
  "CONNECTING",
  "SUCCESS",
  "FAILED",
}

export type PageSettingsType = typeof pageSettingsData;
export type NewsPostType = typeof newsPostData;
