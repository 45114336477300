import React from "react";
import { DotChar } from "../parts/DotChar";
import { InstagramSlider } from "../templates/InstagramSlider";
import { INSTAGRAM_URL } from "../../constants/urls";

export const TopInstagramSection: React.FC = () => {
  return (
    <section className="mb-[100px] md:mb-[150px]">
      <h2
        className="text-center text-[14px] md:text-[22px] mb-[26px] md:mb-[54px]"
        data-page-animation={true}
        data-type-fade={true}
      >
        お店の
        <span className="text-[1.43em] text-primary px-[0.3em]">
          <DotChar
            dotSize="before:w-[6px] md:before:w-[8px] before:h-[6px] md:before:h-[8px]"
            dotColor="before:bg-primary"
          >
            こ
          </DotChar>
          <DotChar
            dotSize="before:w-[6px] md:before:w-[8px] before:h-[6px] md:before:h-[8px]"
            dotColor="before:bg-primary"
          >
            だ
          </DotChar>
          <DotChar
            dotSize="before:w-[6px] md:before:w-[8px] before:h-[6px] md:before:h-[8px]"
            dotColor="before:bg-primary"
          >
            わ
          </DotChar>
          <DotChar
            dotSize="before:w-[6px] md:before:w-[8px] before:h-[6px] md:before:h-[8px]"
            dotColor="before:bg-primary"
          >
            り
          </DotChar>
        </span>
        更新中！
      </h2>
      <InstagramSlider />
      <div
        className="text-center leading-none font-bold tracking-[0.1em] text-md md:text-[22px]"
        data-page-animation={true}
        data-type-fade={true}
      >
        <a
          href={INSTAGRAM_URL}
          target="_blank"
          className="pseudo-before before:inline-block
            before:bg-icon-instagram before:w-[18px] md:before:w-[28px]
            before:h-[18px] md:before:h-[28px]
            before:align-middle before:mr-2 before:mr-[18px]
            before:relative before:-top-[2px] antialiased"
        >
          log_coffee_roasters
        </a>
      </div>
    </section>
  );
};
