import React from "react";
type InnerLayoutProps = {
  children: React.ReactNode;
};

export const InnerLayout: React.FC<InnerLayoutProps> = (props) => {
  const { children } = props;

  return (
    <div className="px-[20px] md:px-[40px] tablet:px-[20px] md:max-w-[1520px] mx-auto">
      {children}
    </div>
  );
};
