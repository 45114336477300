import axios from "axios";
import { WP_API_URLS } from "../constants/urls";

export const getRecentNews = async () => {
  const response = await axios.get(WP_API_URLS.NEW_POST);
  return response.data;
};

export const getPageSettings = async () => {
  const response = await axios.get(WP_API_URLS.PAGE_SETTINGS);
  return response.data.acf;
};
