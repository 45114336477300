import React from "react";
import { InnerLayout } from "./layouts/InnerLayout";
import { ConceptBubble } from "../parts/ConceptBubble";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { BaseTitle } from "../parts/BaseTitle";

export const TopConceptSection: React.FC = () => {
  return (
    <section className="pt-[130px] mb-[80px]">
      <InnerLayout>
        <BaseTitle en="CONCEPT" ja="LOG COFFEE ROASTERS について" />
        <h3
          className="text-center text-[14px] pc-small:text-[18px] md:text-[22px]
          leading-[26px] md:leading-loose font-bold antialiased mb-[24px] md:mb-[36px]"
          data-page-animation={true}
          data-type-fade={true}
        >
          ロースター、バリスタとして、
          <br />
          コーヒーを通して、みなさまの日常に
          <br className="md:hidden" />
          <span className="text-secondary">『小さな幸せ』</span> を
        </h3>
        <div className="pc-medium:max-w-[960px] mx-auto relative pt-[120px] pc-medium:pt-[120px] md:pt-[20px] pb-[130px] md:pb-[142px]">
          <ConceptBubble
            title="自家焙煎"
            position="top-0 left-0"
            rotate="-rotate-[10deg]"
            to="/concept?show=jikabaisen#show_detail"
          >
            <StaticImage
              src="../../images/top/top_concept_img_01.png"
              alt="自家焙煎"
            />
          </ConceptBubble>
          <ConceptBubble
            title="バリスタトレーニング"
            position="top-0 right-0"
            rotate="rotate-[10deg]"
            to="/concept?show=barista_training#show_detail"
          >
            <StaticImage
              src="../../images/top/top_concept_img_02.png"
              alt="バリスタトレーニング"
            />
          </ConceptBubble>
          <ConceptBubble
            title="スペシャルティコーヒー"
            position="bottom-0 left-0"
            rotate="rotate-[10deg]"
            to="/concept?show=specialty_coffee#show_detail"
          >
            <StaticImage
              src="../../images/top/top_concept_img_03.png"
              alt="スペシャルティコーヒー"
            />
          </ConceptBubble>
          <ConceptBubble
            title="卸売事業"
            position="bottom-0 right-0"
            rotate="-rotate-[10deg]"
            to="/concept?show=wholesales#show_detail"
          >
            <StaticImage
              src="../../images/top/top_concept_img_04.png"
              alt="卸売事業"
            />
          </ConceptBubble>
          <div className="max-w-[960px] pc-medium:max-w-[600px] mx-auto">
            <StaticImage src="../../images/top/top_concept.png" alt="" />
          </div>
        </div>
        <div data-page-animation={true} data-type-fade={true}>
          <Link
            to="/concept"
            className="flex justify-center items-center
            mx-auto w-[160px] md:w-[200px] h-[42px] md:h-[50px] border-y border-black mt-[40px] md:-mt-[40px]
            text-border-button md:text-[18px] font-bold antialiased z-[1] relative
            hover:bg-black hover:text-white transition-all duration-300"
          >
            VIEW MORE
          </Link>
        </div>
      </InnerLayout>
    </section>
  );
};
