import React from "react";
import { InnerLayout } from "./layouts/InnerLayout";
import { SpTopMv } from "../templates/SpTopMv";
import { PcTopMv } from "../templates/PcTopMv";
import { DotChar } from "../parts/DotChar";
import { PickupNews } from "../parts/PickupNews";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CLIENT_CONNECTING_STATE, NewsPostType } from "../../constants/api";
import { Loading } from "../svg/Loading";

type TopMvSectionProps = {
  news: NewsPostType | null;
};

export const TopMvSection: React.FC<TopMvSectionProps> = (props) => {
  const { news } = props;
  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <section className="md:overflow-visible">
      <InnerLayout>
        <div className="md:flex md:flex-row-reverse md:items-center relative">
          {/*{windowWidth >= 768 && (*/}
          {/*  <div className="absolute left-0 top-[68px]">*/}
          {/*    <BaseNavigation />*/}
          {/*  </div>*/}
          {/*)}*/}
          {windowWidth < 768 ? <SpTopMv /> : <PcTopMv />}
          <div className="pt-[12px] md:pt-[12%] tablet:pt-[50%] md:w-[55.3%] md:ml-[60px] pc-medium:ml-0">
            <h1
              className="text-md md:text-[24px] pc-small:text-[18px]
            text-center md:text-left font-medium tracking-[0.08em] leading-none"
            >
              １杯のコーヒーで、小さな
              <span className="inline-block text-[1.375em] text-secondary px-[0.3em]">
                <DotChar>幸</DotChar>
                <DotChar>せ</DotChar>
              </span>
              を
            </h1>
            <p className="text-center md:text-md pc-small:text-base md:text-left leading-loose pt-[12px] md:pr-[1.5em]">
              <span className="inline-block text-primary relative pt-[24px]">
                LOG COFFEE ROASTERS
                <span className="text-black text-xs absolute left-[4px] mx-auto top-[4px] font-bold">
                  <span className="mr-[3px]">ログ</span>
                  <span className="mx-[3px]">コーヒー</span>
                  <span className="ml-[3px]">ロースターズ</span>
                </span>
              </span>{" "}
              は
              <br />
              山口市中心商店街にある
              <br className="md:hidden" />
              スペシャルティコーヒー専門の
              <br />
              自家焙煎コーヒーショップです。
            </p>
            <div className="md:pt-[160px] tablet:pt-[100px]">
              <PickupNews news={news} />
            </div>
          </div>
        </div>
      </InnerLayout>
    </section>
  );
};
