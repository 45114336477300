import * as React from "react";
import { useEffect, useState } from "react";
import { BaseLayout } from "../components/views/layouts/BaseLayout";
import { TopMvSection } from "../components/views/TopMvSection";
import { TopConceptSection } from "../components/views/TopConceptSection";
import { TopInstagramSection } from "../components/views/TopInstagramSection";
import { TopServiceSection } from "../components/views/TopServiceSection";
import { TopAccessSection } from "../components/views/TopAccessSection";
import { TopRecruitSection } from "../components/views/TopRecruitSection";
import { TopContactSection } from "../components/views/TopContactSection";
import { IntroMovie } from "../components/views/IntroMovie";
import {
  CLIENT_CONNECTING_STATE,
  NewsPostType,
  PageSettingsType,
} from "../constants/api";
import { getPageSettings, getRecentNews } from "../lib/WordPressHelper";
import { LocalBusinessJsonLd } from "gatsby-plugin-next-seo";

const IndexPage = () => {
  const [newsPost, setNewsPost] = useState<NewsPostType | null>(null);
  const [connectingState, setConnectingState] =
    useState<CLIENT_CONNECTING_STATE>(CLIENT_CONNECTING_STATE.IDLE);
  const [pageSettings, setPageSettings] = useState<PageSettingsType | null>(
    null
  );

  const initNewsPost = async () => {
    const posts = await getRecentNews();
    setNewsPost(posts);
  };

  const initPageSettings = async () => {
    const posts = await getPageSettings();
    setPageSettings(posts);
  };

  useEffect(() => {
    Promise.all([initNewsPost(), initPageSettings()])
      .then(() => {
        setConnectingState(CLIENT_CONNECTING_STATE.SUCCESS);
      })
      .catch(() => {
        setConnectingState(CLIENT_CONNECTING_STATE.FAILED);
      });
  }, []);

  return (
    <BaseLayout pageUrl="/">
      <LocalBusinessJsonLd
        type="CafeOrCoffeeShop"
        id="https://log-coffee-roasters.com"
        name="LOG COFFEE ROASTERS"
        description="LOG COFFEE ROASTERSは山口市中心商店街にあるスペシャルティコーヒー専門の自家焙煎コーヒーショップです。"
        url="https://log-coffee-roasters.com"
        telephone="+81839021371"
        address={{
          streetAddress: "中市町４−１４",
          addressLocality: "山口市",
          addressRegion: "山口県",
          postalCode: "7530086",
          addressCountry: "JP",
        }}
        geo={{
          latitude: "34.1781852",
          longitude: "131.4762848",
        }}
        openingHours={[
          {
            dayOfWeek: [
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ],
            opens: "11:00",
            closes: "19:00",
          },
        ]}
        images={[
          "https://log-coffee-roasters.com/shop-image-1x1.jpg",
          "https://log-coffee-roasters.com/shop-image-4x3.jpg",
          "https://log-coffee-roasters.com/shop-image-16x9.jpg",
        ]}
      />
      <IntroMovie />
      <TopMvSection news={newsPost} />
      <TopConceptSection />
      <TopInstagramSection />
      <TopServiceSection />
      <TopAccessSection
        shopInfo={
          pageSettings ? pageSettings.top_page_settings.shop_info : null
        }
      />
      <div className="block md:flex">
        <TopRecruitSection
          isActive={
            pageSettings
              ? pageSettings.top_page_settings.is_recruit_active
              : false
          }
          connectingState={connectingState}
        />
        <TopContactSection />
      </div>
    </BaseLayout>
  );
};

export default IndexPage;
