import React from "react";
import { Link } from "gatsby";

type ConceptBubbleProps = {
  children: React.ReactNode;
  title: string;
  position:
    | "top-0 left-0"
    | "top-0 right-0"
    | "bottom-0 left-0"
    | "bottom-0 right-0"
    | "";
  rotate?: "-rotate-[10deg]" | "rotate-[10deg]";
  wrapperClass?: string;
  imageClass?: string;
  textClass?: string;
  to: string;
  onClick?: () => void;
  isActive?: boolean;
};

export const ConceptBubble: React.FC<ConceptBubbleProps> = (props) => {
  const {
    children,
    title,
    position,
    rotate = "",
    wrapperClass = "w-[124px] pc-medium:w-[150px] md:w-[200px]",
    imageClass = "w-[80px] pc-medium:w-[120px] md:w-[160px]",
    textClass = "text-[10px] pc-medium:text-[12px] md:text-[16px]",
    to,
    onClick,
    isActive,
  } = props;

  return (
    <div
      className={`
        flex-none 
        ${wrapperClass}
        ${position !== "" ? "absolute" : ""}
        ${position}
        ${rotate}
      `}
    >
      <div data-page-animation={true} data-type-fade={true}>
        {onClick ? (
          <button type="button" className="w-[100%]" onClick={onClick}>
            <div
              className={`
            mx-auto mb-3 md:mb-4
            ${imageClass}
            ${
              isActive
                ? "rounded-full border-primary border-[2px] md:border-[4px]"
                : ""
            }
            `}
            >
              {children}
            </div>
            <div className={`text-center ${textClass} font-bold antialiased`}>
              <span
                className={isActive ? "text-primary" : ""}
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>
            </div>
          </button>
        ) : (
          <Link to={to}>
            <div className={`${imageClass} mx-auto mb-3 md:mb-4`}>
              {children}
            </div>
            <div className={`text-center ${textClass} font-bold antialiased`}>
              <span className="text-primary">#</span>
              <span dangerouslySetInnerHTML={{ __html: title }}></span>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};
