import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export const SpTopMv: React.FC = () => {
  return (
    <div className="full-width">
      <div
        className="w-[100%] relative
            pseudo-after after:block after:w-[100vw]
            after:h-[100%] after:bg-cover after:bg-bottom
            after:bg-mv-mask-sp after:absolute after:bg-repeat
            after:left-0 after:-bottom-0 after:z-10 overflow-hidden
            after:bg-white after:bg-opacity-40"
      >
        <Swiper
          modules={[EffectFade, Autoplay]}
          loop={true}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
          speed={2000}
          effect="fade"
        >
          <SwiperSlide>
            <StaticImage
              src="../../images/top/mv_01_sp.png"
              alt="LOG COFFEE ROASTERS メイン画像"
              className="top-mv-animation"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../images/top/mv_03_sp.png"
              alt="LOG COFFEE ROASTERS メイン画像"
              className="top-mv-animation"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../images/top/mv_02_sp.png"
              alt="LOG COFFEE ROASTERS メイン画像"
              className="top-mv-animation"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../images/top/mv_04_sp.png"
              alt="LOG COFFEE ROASTERS メイン画像"
              className="top-mv-animation"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="w-[120px] mx-auto relative -top-[26px] z-10">
        <StaticImage
          src="../../images/common/logo_main.png"
          alt="LOG COFFEE ROASTERS"
        />
      </div>
    </div>
  );
};
