import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";

export const PcTopMv = () => {
  return (
    <div
      className="max-w-[680px] w-[100%] pc-medium:w-[70%]
      tablet:!w-[60%] relative mr-[5%] pc-medium:mr-[0] mt-[100px]
      pseudo-after after:block after:w-[100%]
      after:h-[100%] after:bg-cover after:bg-repeat after:bg-top
      after:bg-mv-mask after:absolute
      after:left-0 after:-bottom-0 after:z-10 overflow-hidden"
    >
      <Swiper
        modules={[EffectFade, Autoplay]}
        loop={true}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        speed={2000}
        effect="fade"
      >
        <SwiperSlide>
          <StaticImage
            src={`../../images/top/mv_01_pc.png`}
            alt="LOG COFFEE ROASTERS メイン画像"
            className="top-mv-animation"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src={`../../images/top/mv_02_pc.png`}
            alt="LOG COFFEE ROASTERS メイン画像"
            className="top-mv-animation"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src={`../../images/top/mv_03_pc.png`}
            alt="LOG COFFEE ROASTERS メイン画像"
            className="top-mv-animation"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src={`../../images/top/mv_04_pc.png`}
            alt="LOG COFFEE ROASTERS メイン画像"
            className="top-mv-animation"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
