import React from "react";
import { BaseTitle } from "../parts/BaseTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination, Swiper as Sw } from "swiper";
import { SliderCafeContents } from "./SliderCafeContents";
import { ViewMoreButton } from "../parts/ViewMoreButton";
import { SliderBeansContents } from "./SliderBeansContents";
import { SliderWholesaleContents } from "./SliderWholesaleContents";
import { SliderSupportContents } from "./SliderSupportContents";
import { createRoot } from "react-dom/client";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

export const TopServiceSection: React.FC = () => {
  const insertTextInPagination = (swiper: Sw) => {
    const bullets = swiper.pagination.bullets;
    const texts = ["Cafe", "Beans & Supplies", "Wholesale", "Support"];
    for (let i = 0; i < bullets.length; i++) {
      const bullet = swiper.pagination.bullets[i];
      bullet.classList.add("relative", "group");
      const bulletRoot = createRoot(bullet);
      bulletRoot.render(
        <span
          className="leading-none absolute left-[14px] inset-0 m-auto block w-[250px] pointer-events-none
          before:content-[''] before:mx-3 before:w-[60px]
          before:h-[1px] before:inline-block before:bg-black before:align-middle
          transition-all duration-300
          opacity-0 -translate-x-[30px] group-hover:opacity-100 group-hover:translate-x-0"
        >
          {texts[i]}
        </span>
      );
    }
  };

  return (
    <section className="mb-[60px] lg:mb-[90px]">
      <BaseTitle en="SERVICE" ja="暮らしに溶け込む自家焙煎コーヒー" />
      <div className="lg:flex lg:justify-center lg:items-center relative xxl:h-[580px] lg:h-400px">
        <div
          className="absolute w-[100%] h-[100%] absolute top-0 left-0
          bg-white z-[1] bg-opacity-10 pointer-events-none w-[100%] h-[100%] z-[2]"
        />
        <Swiper
          modules={[Autoplay, EffectFade, Pagination]}
          effect="fade"
          loop={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          speed={800}
          onPaginationRender={insertTextInPagination}
          className="w-[100%] h-[100%] relative"
        >
          <SwiperSlide className="w-[100%] h-[100%] relative">
            <SliderCafeContents title="Cafe" subtitle="喫茶">
              <p className="text-center mb-[30px]">
                メニューに合わせたこだわりの焙煎豆。
                <br />
                お好みのドリンクで
                <span className="text-[1.4em] text-primary">ホッ</span> と一息。
              </p>
              <ViewMoreButton href="/service#cafe" />
            </SliderCafeContents>
          </SwiperSlide>
          <SwiperSlide className="w-[100%] h-[100%] relative">
            <SliderBeansContents
              title="Beans<br /> & Supplies"
              subtitle="コーヒー豆・関連用品の販売"
            >
              <p className="text-center mb-[30px] xxl:text-base lg:text-[sm]">
                メニューに合わせたこだわりの焙煎豆。
                <br />
                日常の中に{" "}
                <span className="text-[1.4em] text-secondary">
                  小さな幸せ
                </span>{" "}
                を。
              </p>
              <ViewMoreButton href="/service#beans-supplies" />
            </SliderBeansContents>
          </SwiperSlide>
          <SwiperSlide className="w-[100%] h-[100%] relative">
            <SliderWholesaleContents
              title="Wholesale"
              subtitle="事業者さま向け販売"
            >
              <p className="text-center mb-[30px] xxl:text-base lg:text-[sm]">
                事業者さま向けのコーヒー豆販売と
                <br />
                関連器具の選定・販売
              </p>
              <ViewMoreButton href="/service#wholesale" />
            </SliderWholesaleContents>
          </SwiperSlide>
          <SwiperSlide className="w-[100%] h-[100%] relative">
            <SliderSupportContents title="Support" subtitle="サポート業務">
              <p className="text-center mb-[30px]">
                コーヒーに関するご相談など
                <br />
                トータルサポートいたします
              </p>
              <ViewMoreButton href="/service#support" />
            </SliderSupportContents>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};
